import React from 'react'
import Portfolio from '../../components/portfolio/Portfolio'
// import Info from '../../pages/info/Info'

function Home() {
  return (
    <div id="home-page">
      <div>
        <Portfolio />
      </div>
    </div>
  )
}

export default Home